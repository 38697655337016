<template>
    <div>
        <div class="container-lg" >
            <div class="mt-2 mb-3 d-flex align-items-center"><Slideshow /></div>
        </div>
        <JoinCause id="about-us" />
        <div class="container-lg py-3">
            <div class="display-1 text-white fw-bolder mb-0">Be The Solution</div>
            <div class="display-1 text-white fw-bolder mb-0">Choose What You Want to Be</div>
        </div>
        <div id="services"><Section1 /></div>
        <Section2 />
        <div class="container-lg py-3" id="news-section">
            <News />
        </div>
    </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import { BCarousel, BCarouselSlide } from 'bootstrap-vue'

import store from '@/store'
import Slideshow from './component/slideshow.vue';
import JoinCause from './component/join-cause.vue';
import News from './component/news.vue';
import Section1 from './component/section.vue';
import Section2 from './component/section-2.vue';

export default {
    components: {
        Swiper, SwiperSlide,
        BCarousel, BCarouselSlide, Slideshow, JoinCause, News, Section1, Section2
    },
    computed: {
        banners() {
            return store.state.banners.BANNERS || []
        },
        ads() {
            return store.state.banners.ADS || []
        },
    },
    mounted() {
        store.dispatch('banners/GetBanners')
        store.dispatch('banners/GetAds')
    },
    data() {
        return {
            learn_more_banner: [
                { url: 'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/pet-shop-cover-template-design-7fbcea6de5ed45af0716f61106314548_screen.jpg?ts=1622085870' },
                { url: 'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/pet-shop-banner-design-template-4c29604da3e3601ec0a5d464e2cbeb4b_screen.jpg?ts=1621925071' }
            ],
            swiperOptions: {
                slidesPerView: 1,
                spaceBetween: 10,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                autoplay: {
                    delay: 3000,
                },
            },
            swiperOptionsBanner: {
                slidesPerView: 1,
                spaceBetween: 10, 
                autoplay: {
                    delay: 3000,
                },
            },
        }
    }
}

</script>

<style> .input-group.search span {
     border-right: unset;
     border-color: #FFAD32;
     border-radius: 10px 0 0 10px;
     font-size: 24px;
     color: #FFAD32;
     background-color: transparent;
 }

 .input-group.search input {
     margin-left: 0;
     padding-left: 0;
     border-left: unset;
     border-radius: 0 10px 10px 0;
     border-color: #FFAD32;
     height: 40px;
     color: white;
     background-color: transparent !important;
 }

 .swiper-button-prev,
 .swiper-button-next {
     --swiper-theme-color: transparent;
     background: #ffffff;
     box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.2);
     width: 40px;
     height: 40px;
     border-radius: 50%;
     padding: 0 !important;
 }

 .swiper-button-prev::after,
 .swiper-button-next::after {
     content: unset !important;
 }
</style> 